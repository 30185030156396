import * as R from 'ramda'
import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {
  AppBar,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Toolbar
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons/'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { mapIndexed } from '../lib/Helpers'
import Headroom from 'react-headroom'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [menuOpen, setMenuOpen] = React.useState(false)
  const data = useStaticQuery(graphql`
    {
      siteSettings: sanitySiteSettings {
        parent_site
        parent_site_url
        logo {
          asset {
            fluid(maxHeight: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        parent_site_url
        parent_site
      }
      categories: allSanityCategory {
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
      exhibitors: allSanityExhibitors {
        edges {
          node {
            title
            company_primary_contact
            slug {
              current
            }
          }
        }
      }
    }
  `)
  const settings = data.siteSettings
  const { edges: categories } = data.categories
  const { edges: exhibitors } = data.exhibitors

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen)
  }

  const onAutocompleteChange = (event, value) => {
    navigate(`/exhibitor/${value.node.slug.current}`)
  }

  return (
    <Headroom>
      <header className="header">
        <AppBar position="static" className="appbar" elevation={0}>
          <Toolbar>
            <AniLink
              cover
              to="/"
              duration={1.25}
              direction="right"
              bg={`
              url(${settings.logo.asset.fluid.src})
              center / 50% /* position / size */
              no-repeat        /* repeat */
              fixed            /* attachment */
              padding-box      /* origin */
              content-box      /* clip */
              black            /* color */
            `}
              className="logo"
            >
              <img
                src={settings.logo.asset.fluid.src}
                alt="Agility Prime Logo"
              />
            </AniLink>
            <div>
              <IconButton
                onClick={toggleDrawer}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <FontAwesomeIcon icon={faBars} className="fa-bars-icon" />
              </IconButton>
            </div>
            <Drawer
              className="main-menu"
              open={drawerOpen}
              anchor="right"
              onClose={toggleDrawer}
              classes={{ paper: 'drawer' }}
            >
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    style={{
                      color: '#f4df21',
                      textAlign: 'right '
                    }}
                  >
                    <IconButton
                      onClick={toggleDrawer}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListSubheader>
                }
              >
                <Autocomplete
                  autofill="true"
                  options={R.compose(
                    R.sort(R.ascend(R.path(['node', 'title'])))
                  )(exhibitors)}
                  getOptionLabel={({ node: option }) =>
                    option.title || option.company_primary_contact
                  }
                  style={{ width: '90%', margin: '0 auto 40px' }}
                  className="search-box"
                  onChange={onAutocompleteChange}
                  classes={{
                    popper: 'popper-autocomplete'
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      className="search-box-label"
                      label="Search for a Company"
                      variant="outlined"
                      color="secondary"
                    />
                  )}
                />
                <Divider />
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{
                    color: '#f4df21',
                    fontFamily: 'Montserrat',
                    fontWeight: 600
                  }}
                >
                  Menu
                </ListSubheader>
                <AniLink
                  cover
                  to="/"
                  duration={1.25}
                  direction="right"
                  bg={`
                      url(${settings.logo.asset.fluid.src})
                      center / 50% /* position / size */
                      no-repeat        /* repeat */
                      fixed            /* attachment */
                      padding-box      /* origin */
                      content-box      /* clip */
                      black            /* color */
                    `}
                >
                  <ListItem
                    button
                    onClick={toggleDrawer}
                    style={{ color: 'white' }}
                  >
                    <ListItemText primary="Home" className="parent-list-item" />
                  </ListItem>
                </AniLink>
                <ListItem
                  button
                  onClick={handleMenuClick}
                  style={{ color: 'white' }}
                >
                  <ListItemText
                    primary="Categories"
                    className="parent-list-item"
                  />
                  {menuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {mapIndexed(({ node: cat }, index) => {
                      return (
                        <AniLink
                          cover
                          duration={1}
                          direction="down"
                          className="image"
                          to={`/category/${cat.slug.current}`}
                          bg="#000"
                          key={index}
                        >
                          <div
                            role="presentation"
                            onClick={toggleDrawer}
                            onKeyDown={toggleDrawer}
                          >
                            <ListItem button className="nested-menu-item">
                              <ListItemText primary={cat.title} />
                            </ListItem>
                          </div>
                        </AniLink>
                      )
                    })(categories)}
                  </List>
                </Collapse>
                <a
                  href={settings.parent_site_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem button style={{ color: 'white' }}>
                    <ListItemText
                      primary={`${settings.parent_site} Website`}
                      className="parent-list-item"
                    />
                  </ListItem>
                </a>
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
      </header>
    </Headroom>
  )
}
